<template>
	<div class="place-order">
		<van-tabs color="#2388FF" v-model="activeName" @click="tabsClick">
		  <van-tab title="全部" name="0"></van-tab>
		  <van-tab title="C端" name="10"></van-tab>
		  <van-tab title="B端" name="20"></van-tab>
		</van-tabs>
		<!-- 查询 -->
		<el-input placeholder="请输入查询的备品号" v-model="inputVal" class="input-search">
			<el-button slot="append" @click="searchClick">查询</el-button>
		</el-input>
		<!-- 查询列表 -->
		<el-empty description="暂无数据" :image-size="80" v-if="goodsList.total==0"></el-empty>
		<div class="order-list">
			<div class="order-list-item" v-for="(item,index) in goodsList.data" :key="index">
				<div class="order-name">{{item.goods_name}}
          <van-tag type="primary">{{item.type_id == 10 ? 'C' : 'B' }}端</van-tag>
        </div>
				<div class="content" style="margin: 0 0 0.1rem 0">
					<div class="left">
						<span class="connective-item">{{item.buy_code}}</span>
					</div>
					<div class="right">
						<span class="store">库存:<i>{{ item.type_id == 20 ? item.inventory_num : '' }}</i></span>
						<button v-if="item.inventory_type == InventoryTypeEnum.ACCURATE.value" class="btn-blue" @click="viewClick(item)">查看</button>
					</div>
				</div>
				<div class="content">
					<div class="left">
						<span class="price">￥{{item.goods_price_min}}</span>
						<span class="connective-item" v-for="(items,indexs) in item.word" :key="indexs">{{items.word}}</span>
					</div>
					<div class="right">
						<button class="btn-orange" @click="connectiveClick(item)">关联</button>
						<button class="btn-orange" @click="handleAdd(item)">添加</button>
						<button class="btn-orange" @click="sendClick(item)">发送</button>
					</div>
				</div>
			</div>
		</div>
		<van-pagination v-model="currentPage" :total-items="goodsList.total" :show-page-size="pagesize" force-ellipses
			@change="pageChange" style="margin: 0 0 0.15rem 0;" />
		<!-- 库存详情 -->
		<van-popup v-model="storeShow" closeable close-icon="close" round class="store-detail">
			<div class="title">库存详情</div>
			<van-cell-group>
				<van-cell :title="item.warehouse_name" :value="'数量:' + item.goods_num" v-for="(item,index) in storeList" :key="index"/>
			</van-cell-group>
		</van-popup>
		<!-- 已选配件列表 -->
		<div class="WeCom-title">
			<img src="../../../assets/img/icon-title.png" />已选配件列表
		</div>
		<el-empty description="暂无已选配件,请查询添加" :image-size="80" v-if="chooseList.length==0"></el-empty>
		<div class="order-list choose-list">
			<div class="order-list-item" v-for="(item,index) in chooseList" :key="index">
				<div class="order-name">{{item.goods.goods_name}}</div>
				<div class="order-code">{{item.goods.buy_code}}</div>
				<div class="content">
					<div class="left">
						<span class="price">￥{{item.goods.goods_price_min}}</span>
					</div>
					<div class="right">
						<van-stepper v-model="item.goods_num" step='1' integer button-size="25"
							@change="onChangeNum($event, item)" />
						<button class="btn-red" @click="deleteItem(item.id)">删除</button>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部信息 -->
		<div class="place-order-bottom">
			<div class="left">
				<span>总计：￥<em>{{totalPrice}}</em></span>
				<span>数量：<em>{{chooseList.reduce((sum, e) => sum + e.goods_num, 0)}}</em>件</span>
			</div>
			<van-button type="warning" @click="generateOrder">生成订单</van-button>
		</div>
		<!-- 关联弹窗 -->
		<popupInput ref="popupInput" placeholder="请输入关联词汇" @defineClick="defineClick"></popupInput>
	</div>
</template>

<script>
	import * as QywxApi from '@/api/qywx'
	import * as CartApi from '@/api/cart'
	import popupInput from '../components/popupInput.vue'
    import {InventoryTypeEnum} from "../../../common/enum/user";
    import config from '../config'
    import {
        checkRedirect,
        initSdk,
        invoke,
        asyncCall,
        call
    } from 'wecom-sidebar-jssdk';
  import {getInventoryListForTypeC} from "../../../api/qywx";
	export default {
		components: {
			popupInput
		},
		data() {
			return {
				activeName: 1,
        InventoryTypeEnum,
				storeList:[],
				storeShow: false,
				currentPage: 1,
				pagesize: 5,
				inputVal: '',
				chooseList: [{
					name: '左侧安全带总成左侧安全带总成左侧安全带总成',
					code: '8212015-D9031',
					price: 10,
					number: 1
				}],
				goodsList: {
					total: 0
				},
				cartCategoryId: '',
				totalPrice: 0.00,
        typeId: ''  //商品类型
			}
		},
		created() {
      initSdk(config, this.fetchSignatures)
			this.getCartCategoryId()
		},
		methods: {
			tabsClick(e){
        this.typeId = e
        this.currentPage = 1
        this.searchClick()
				console.log(e)
			},
      async fetchSignatures() {
        let url = window.location.href
        let qywxUserId = localStorage.getItem('qywxUserId')
        if (url.split('?').length > 1 && qywxUserId) {
          url = url.split('?')[0]
        }
        let res = await QywxApi.getSignatures({
          url: url
        });
        let signatures = res.data.signatures
        return signatures
      },
			// 发送
			sendClick(item){
                const goodsId = item.goods_id
                this.sendMiniProgram('商品详情', `pages/goods/detail.html?goodsId=${goodsId}`, item.goods_image)
			},
			// 查看库存
			viewClick(item) {
        if (item.type_id == 10) {
          QywxApi.getInventoryListForTypeC({ buy_code: item.buy_code, sup_code: item.sup_code }).then(res => {
            this.storeList = res.data.list
            this.storeShow = true
          })
        } else {
          this.storeList = item.inventoryList
          this.storeShow = true
        }
			},
			// 生成订单
			generateOrder() {
                if (this.chooseList.length == 0) {
                    this.$toast.fail('请选择商品！');
                    return
                }
                let imgUrl = this.chooseList[0].goods.goods_image
				this.$dialog.confirm({
						title: '提示',
						message: '确定生成订单吗？',
					})
					.then(() => {
						QywxApi.addQywxOrder({
								categoryId: this.cartCategoryId
							})
							.then(res => {
                                const orderId = res.data.orderId
                                this.sendMiniProgram('客服代下单详情',
                                    `pages/cart_qywx/index.html?qywxOrderId=${orderId}`,
                                    imgUrl
                                )
								this.getCartList()
								this.$toast.success('成功生成订单！');
							})
							.catch(err => {
								this.$toast.fail(err.errMsg);
							})
					})
					.catch(() => {
						// on cancel
					});
			},
			// 查询
			searchClick() {
				if (this.inputVal) {
					const param = {
						sortType: 'all',
						sortPrice: 1,
						categoryId: 0,
            type_id: this.typeId,
						goodsName: this.inputVal,
						page: this.currentPage,
						pagesize: this.pagesize
					}
					QywxApi.goodsList(param).then(res => {
						let newList = res.data.list
						newList.data.forEach((item) => {
							if (item.replaceGoods) {
								item.replaceGoods.is_replace = true
								item.replaceGoods = [item.replaceGoods]
							}
						})
						this.goodsList = newList
					})
				} else {
					this.$toast.fail('请输入查询的备品号！');
				}
			},
			// 关联
			connectiveClick(item) {
				this.$refs.popupInput.show(item.goods_id, 'goodsWord', '')
			},
			// 关联弹窗确定
			defineClick(goods_id, type, word) {
				QywxApi.addGoodsWord({
					goods_id: goods_id,
					word: word
				}).then(res => {
					this.searchClick()
					this.$toast.success('关联成功！');
				})
			},
			//添加
			addClick(item) {
				let index = this.chooseList.findIndex((i) => {
					return i.code === item.code
				})
				if (index !== -1) {
					this.chooseList[index].number = this.chooseList[index].number + 1
				} else {
					this.chooseList.push({
						name: item.name,
						code: item.code,
						price: item.price,
						number: 1
					})
				}
				this.$toast.success('添加成功！');
			},
			// 删除
			delClick(index) {
				this.chooseList.splice(index, 1)
				this.$toast.success('删除成功！');
			},
			pageChange(page) {
				this.currentPage = page
				this.searchClick()
			},
			getCartCategoryId() {
				QywxApi.getQywxCartCategoryId().then(res => {
					this.cartCategoryId = res.data.id
					this.getCartList()
				})
			},
			getCartList() {
				const app = this
				CartApi.list()
					.then(result => {
						// console.log(result)
						app.chooseList = result.data.list.filter(e => e.category_id == this.cartCategoryId)
						app.onCalcTotalPrice()
					})
			},
			onCalcTotalPrice() {
				const app = this
				// 选中的商品记录
				const checkedList = app.chooseList

				// 计算总金额
				let tempPrice = 0;
				checkedList.forEach(item => {
					// 商品单价, 为了方便计算先转换单位为分 (整数)
					const unitPrice = item.goods.skuInfo.goods_price * 100
					tempPrice += unitPrice * item.goods_num
				})
				app.totalPrice = (tempPrice / 100).toFixed(2)
			},
			onUpdateCartNum(item, newValue) {
				const app = this
				CartApi.update(item.goods_id, 0, newValue, app.cartCategoryId)
					.then(result => {
						app.getCartList()
						// 更新商品数量
						app.total = result.data.cartTotal
						// 重新计算合计金额
						app.onCalcTotalPrice()
					})
					.catch(err => {
						// 还原商品数量
						this.$message.error(err.errMsg)
					})
					.finally(() => {
						app.getCartList()
					})
			},
			deleteItem(id) {
				let app = this
				this.$dialog.confirm({
				  title: '提示',
				  message: '确定要删除该行吗?',
				})
				  .then(() => {
				    let ids = []
				    ids.push(id)
				    app.onClearCart(ids)
				  })
				  .catch(() => {
				    // on cancel
				  });
			},
			// 确认删除商品
			onClearCart(ids) {
				console.log('del:', ids)
				const app = this
				CartApi.clear(ids).then(result => {
					this.$toast.success('删除成功');
					app.getCartList()
				})
			},
			handleAdd(item) {
				const app = this
				CartApi.add(item.goods_id, 0, 1, app.cartCategoryId).then(result => {
					this.$toast.success('添加成功');
				}).finally(() => {
					app.getCartList()
				})
			},
			onChangeNum(val, item) {
				this.onUpdateCartNum(item, val)
			},
            async sendMiniProgram(title, page, imgUrl) {
                const res1 = await invoke('sendChatMessage', {
                    msgtype: "miniprogram", //消息类型，必填
                    enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                    miniprogram: {
                        appid: config.miniprogramAppId, //小程序的appid，企业已关联的任一个小程序
                        title: title, //小程序消息的title
                        imgUrl: imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                        page: page,
                    }
                })
                console.log(res1)
            }
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/css/wecom.scss";
	.van-tabs{
		padding: 0 0 0.2rem 0;
	}
</style>
