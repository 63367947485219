import Enum from '../enum'

/**
 * 枚举类：余额变动场景
 * PayStatusEnum
 */
export default new Enum([
  { key: 'NONE', name: '不显示', value: 0 },
  { key: 'BLUR', name: '显示"有/无"', value: 10 },
  { key: 'ACCURATE', name: '精确显示', value: 20 }
])
