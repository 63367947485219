import Enum from '../enum'

/**
 * 枚举类：入驻申请状态
 * ApplyStatusEnum
 */
export default new Enum([
  {key: 'APPLY', name: '审核中', value: 10},
  {key: 'PASS', name: '审核通过', value: 20},
  {key: 'REJECT', name: '审核不通过', value: 30}
])
