import Enum from '../enum'

/**
 * 枚举类：修理厂类型
 * PayStatusEnum
 */
export default new Enum([
  { key: 'STATION', name: '服务站', value: 10 },
  { key: 'SHOP', name: '社会修理厂', value: 20 }
])
